<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-row>
        <b-col cols="12">
          <item-form />
          <b-button
            variant="danger"
            @click="saveData"
          >
            Kaydet
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton,
} from 'bootstrap-vue'
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import ItemForm from '@/views/Sales/Consultant_goals/ItemForm.vue'

export default {
  name: 'Edit',
  components: {
    BRow,
    BCol,
    BButton,
    ValidationObserver,
    ItemForm,
  },
  data() {
    return {
      required,
      locale: 'tr',
      months: [
        {
          id: 1,
          title: 'Ocak',
        },
        {
          id: 2,
          title: 'Şubat',
        },
        {
          id: 3,
          title: 'Mart',
        },
        {
          id: 4,
          title: 'Nisan',
        },
        {
          id: 5,
          title: 'Mayıs',
        },
        {
          id: 6,
          title: 'Haziran',
        },
        {
          id: 7,
          title: 'Temmuz',
        },
        {
          id: 8,
          title: 'Ağustos',
        },
        {
          id: 9,
          title: 'Eylül',
        },
        {
          id: 10,
          title: 'Ekim',
        },
        {
          id: 11,
          title: 'Kasım',
        },
        {
          id: 12,
          title: 'Aralık',
        },
      ],
    }
  },
  computed: {
    brands() {
      return this.$store.getters['brands/getBrands']
    },
    users() {
      return this.$store.getters['users/getUsers']
    },
    consultantGoal() {
      return this.$store.getters['consultantGoals/getConsultantGoal']
    },
    saveStatus() {
      return this.$store.getters['consultantGoals/getConsultantGoalSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    localize(this.locale)
    this.getBrands()
    this.getUsers()
    this.getConsultantGoal()
  },
  methods: {
    getConsultantGoal() {
      this.$store.dispatch('consultantGoals/consultantGoalView', this.$route.params.id)
    },
    getBrands() {
      this.$store.dispatch('brands/brandsList', {
        where: {
          'com_brand.salestatus': 1,
        },
      })
    },
    getUsers() {
      this.$store.dispatch('users/usersList', {
        where: {
          'com_user.id_com_department': 2,
          'com_user.status': 1,
        },
      })
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    saveData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.$store.dispatch('consultantGoals/consultantGoalSave', this.consultantGoal)
        }
      })
    },
  },
}
</script>
